// import React from "react"

export const lifecycleContent = {
  title: "Triggered at key moments in the customer lifecycle",
  block: [
    {
      img: require('../images/freetrial.svg'),
      text: "Start of free trial",
    },
    {
      img: require('../images/firstpayment.svg'),
      text: "First payment"
    },
    {
      img: require('../images/subsequentpayment.svg'),
      text: "Subsequent payment"
    },
    {
      img: require('../images/pause.svg'),
      text: "Suspended"
    },
    {
      img: require('../images/resume.svg'),
      text: "Resumed"
    },
    {
      img: require('../images/delete.svg'),
      text: "Cancelled"
    },
  ]
}